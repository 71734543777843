import React, { Component, useContext, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import DetailItemsTable, {
  TableContext,
} from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import reportsService from "../../../services/sweep/reportsService";
import mandateService from "../../../services/mandate/mandateService";
import institutionsService from "../../../services/auth/institutionsService";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../../services/auth/authService";
import bvnAccountMapService, {
  retrieveAccountMapsByBvn,
} from "../../../services/linking/bvnAccountMapService";
import bvnService from "../../../services/mandate/bvnService";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import swal from "sweetalert";
import mandateSweepService from "../../../services/sweep/mandateSweepService";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);

  const toggleOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

export interface IMandateDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
  switchRecoveryMode?: boolean;
  showGuarantorDetails?: boolean;
  secondaryMandateTransactions?: any;
  totalSecondaryMandateTransactions?: number;
  fetchingSecondaryMandateTransactions?: boolean;
  secondaryMandateTransactionsCurrentPage?: number;
  // guarantorTransactions?: any;
}

interface IMandateDetailState {
  fetchingBVNAccounts: boolean;
  fetchingMandateTransactions: boolean;
  mandateTransactionsCurrentPage: number;
  totalMandateTransactions: number;
  mandateTransactions: any;
  bvnAccounts: any;
  guarantorItem: any;
  showGuarantorDetailsModal: boolean;
  comment: string;
  guarantorTransactions: any;
  fetchingSecondaryMandateTransactions: boolean;
  secondaryMandateTransactions: any;
  totalSecondaryMandateTransactions: number;
  secondaryMandateTransactionsCurrentPage: number;
}

class MandateDetail extends Component<
  IMandateDetailProps,
  IMandateDetailState
> {
  _isMounted = false;
  constructor(props: IMandateDetailProps) {
    super(props);
    this.state = {
      guarantorItem: {},
      fetchingBVNAccounts: false,
      fetchingMandateTransactions: false,
      mandateTransactionsCurrentPage: 1,
      mandateTransactions: [],
      totalMandateTransactions: 0,
      bvnAccounts: [],
      showGuarantorDetailsModal: false,
      comment: "",
      guarantorTransactions: [],
      fetchingSecondaryMandateTransactions: false,
      secondaryMandateTransactions: [],
      totalSecondaryMandateTransactions: 0,
      secondaryMandateTransactionsCurrentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  static defaultProps = {
    switchRecoveryMode: true,
  };

  handleOnRecoveryModeSwitch = async (item) => {
    try {
      await mandateService.recoveryModeSwitch(item.id);
      this.props.toggleDetailsModal(item);
      //   this.handlePageChange(this.state.currentPage);
      toast.info(
        `Switched${
          item.status === "RecoveryMode" ? " From " : " To "
        }Recovery Mode`,
        {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        }
      );
    } catch (error) {}
  };

  // fetchDetails = async (item: any) => {
  //   // debugger;
  //   // console.log("item: ", item);
  //   try {
  //     this.setState({ fetchingBVNAccounts: true });
  //     const bvnAccounts = await bvnService.retrieveAccountsByBVN(item.bvn);
  //     this.setState({
  //       bvnAccounts: _.isEmpty(bvnAccounts.data)
  //         ? []
  //         : bvnAccounts.data[0].linkedAccounts,
  //       fetchingBVNAccounts: false,
  //     });

  //     this.handleOnMandateTransactionsPageChange(
  //       this.state.mandateTransactionsCurrentPage,
  //       item.loanReference
  //     );
  //   } catch (error) {
  //   } finally {
  //     this.setState({
  //       fetchingBVNAccounts: false,
  //     });
  //   }
  // };

  // handle on comment chnge
  handleOnCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ comment: e.currentTarget.value });
  }

  handleOnMandateTransactionsPageChange = async (page, loanReference) => {
    try {
      this.setState({ fetchingMandateTransactions: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const institution = await institutionsService.retrieveInstitutionById(
        decodedToken?.["InstitutionId"]
      );
      // console.log("fetching mandate transactions...");
      const mandateTransactions =
        await reportsService.retrieveRepaymentsByCustomerLoanReference(
          institution.data.code,
          this.props.item.loanReference,
          Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
          page
        );
      // console.log("mandate transactions: ", mandateTransactions);
      this.setState({
        mandateTransactions: mapEntityList({
          entityList: mandateTransactions.data.item2,
          properties: [
            {
              oldName: "linkedAccountInstitution",
              newName: "debitedInstitution",
            },
            { oldName: "linkedAccountNo", newName: "inflowAccount" },
            { oldName: "customerType", newName: "customerType" },
            { oldName: "amountDebited", newName: "amountDebited" },
            // { oldName: "thirdPartySweepService", newName: "thirdParty" },
            { oldName: "timeSwept", newName: "sweepTime" },
            { oldName: "transactionStatus", newName: "status" },
            // {
            //   isContextual: true,

            // }
          ],
        }),
        totalMandateTransactions: _.isEmpty(mandateTransactions.data)
          ? 0
          : mandateTransactions.data.item1,
        fetchingMandateTransactions: false,
        mandateTransactionsCurrentPage: page,
      });
    } catch (error) {
      this.setState({ fetchingMandateTransactions: false });
    }
  };

  async cancelMandate() {
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to cancel this mandate?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // console.log("not gonna cancel the mandate");
        } else {
          await mandateService.cancelMandate(
            this.props.item.id,
            this.state.comment
          );

          toast.info(`Mandate cancelled successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      });
    } catch (error) {
      // console.log("error: ", error);
      toast.error(`Unable to cancel mandate. `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  }

  async fetchFullGuarantorMandate(request) {
    const { item } = this.props;
    this.setState({ fetchingSecondaryMandateTransactions: true });
    let fullRequest = this.props.item.secondaryMandates.filter(
      (item) => item.id === request.id
    )[0];

    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const sweepMandateInfo =
      await mandateSweepService.retrieveSecondaryMandateInfo(
        decodedToken?.["InstitutionCode"] || "",
        item.loanReference,
        fullRequest.bvn
      );

    const response = await Promise.allSettled([
      retrieveAccountMapsByBvn(request.bvn),
    ]);
    const bvnaccounts =
      response[0].status === "fulfilled" ? response[0].value.data : {};

    fullRequest.totalAmountDeducted = sweepMandateInfo.data.totalAmountDeducted;
    fullRequest.totalNoOfTimesDeducted =
      sweepMandateInfo.data.totalNoOfTimesDeducted;
    fullRequest.lastAmountDeducted = sweepMandateInfo.data.lastAmountDeducted;
    fullRequest.lastDeductionDate = sweepMandateInfo.data.lastDeductionDate;

    fullRequest.bvnAccounts = bvnaccounts?.accounts;
    fullRequest.firstDateLinked = bvnaccounts?.firstDateLinked;
    fullRequest.lastDateLinked = bvnaccounts?.lastDateLinked;

    console.log("the full request", fullRequest);
    // console.log("checking: ", this.props.item);
    this.handleOnSecondaryMandateTransactionsPageChange(
      this.state.secondaryMandateTransactionsCurrentPage,
      this.props.item.loanReference
    );

    this.setState({
      guarantorItem: fullRequest,
      // guarantorTransactions: guarantorTransactions.data,
      fetchingSecondaryMandateTransactions: false,
    });
  }

  handleOnSecondaryMandateTransactionsPageChange = async (
    page,
    loanReference
  ) => {
    // console.log("fetching mandate transactions...");
    try {
      this.setState({ fetchingSecondaryMandateTransactions: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const mandateTransactions =
        await reportsService.retrieveGuarantorRepaymentsByCustomerLoanReference(
          decodedToken?.["InstitutionCode"] || "",
          this.props.item.loanReference,
          Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
          page
        );
      // console.log("mandate transactions: ", mandateTransactions);
      this.setState({
        secondaryMandateTransactions: mapEntityList({
          entityList: mandateTransactions.data.item2,
          properties: [
            {
              oldName: "linkedAccountInstitution",
              newName: "debitedInstitution",
            },
            { oldName: "linkedAccountNo", newName: "inflowAccount" },
            { oldName: "customerType", newName: "customerType" },
            { oldName: "amountDebited", newName: "amountDebited" },
            // { oldName: "bvn", newName: "bvn" },
            { oldName: "timeSwept", newName: "sweepTime" },
          ],
        }),
        totalSecondaryMandateTransactions: _.isEmpty(mandateTransactions.data)
          ? 0
          : mandateTransactions.data.item1,
        fetchingSecondaryMandateTransactions: false,
        secondaryMandateTransactionsCurrentPage: page,
      });
    } catch (error) {
      this.setState({ fetchingSecondaryMandateTransactions: false });
    }
  };

  handleOnToggleMandateModal() {
    // console.log("item :: ", this.props.item);
    if (this.props.item.hasGuarantor && this._isMounted) {
      this.setState({
        showGuarantorDetailsModal: !this.state.showGuarantorDetailsModal,
      });
    }
  }
  render() {
    const tableContext: TableContext = {
      name: "status",
      // primaryValue: "Successful",
      successValue: "Successful",
      dangerValue: "Failed",
    };
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    const {
      fetchingMandateTransactions,
      mandateTransactionsCurrentPage,
      totalMandateTransactions,
      mandateTransactions,
      comment,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onShow={() => {
          this.handleOnMandateTransactionsPageChange(
            this.state.mandateTransactionsCurrentPage,
            this.props.item.loanReference
          );
          // console.log("props: ", this.props);
          this.handleOnSecondaryMandateTransactionsPageChange(
            this.state.secondaryMandateTransactionsCurrentPage,
            this.props.item.loanReference
          );
          // console.log(
          //   "mandate transactions: ",
          //   this.state.secondaryMandateTransactions
          // );
        }}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                {this.props.showGuarantorDetails ? (
                  <React.Fragment>
                    <i className="fab fa-first-order-alt fa-sm mr-3" />
                    Mandate Details -{" "}
                    <span className="font-weight-bold">{item.name}</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="fab fa-first-order-alt fa-sm mr-3" />
                    Mandate Details -{" "}
                    <span className="font-weight-bold">
                      {item.customerName}
                    </span>
                  </React.Fragment>
                )}
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.showGuarantorDetails ? (
            <React.Fragment>
              <div className="card">
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            General Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Name"
                                      value={item.name}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="BVN"
                                      value={item.bvn}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Account Phone Number"
                                      value={item.phoneNumber}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Loan Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Guarantor's Mandate Reference"
                                      value={item.requestReference}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Guaranteed Amount"
                                      value={textFormatService.convertKoboToNaira(
                                        item.guaranteedAmount,
                                        2
                                      )}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Guaranteed Percentage"
                                      value={item.guaranteeingPercentage}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Mandate Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={4} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Mandate Type"
                                      value={item.mandateType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Date Created"
                                      value={item.dateCreated}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Request Status"
                                      // value={textFormatService.splitAtUpper(
                                      //   item.requestStatus
                                      // )}
                                      value={item.requestStatus}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={4} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  ></dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Sweep Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Sweep Type"
                                      value={item.sweepType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label={`Total Amount Deducted`}
                                      value={`${textFormatService.convertKoboToNaira(
                                        item.totalAmountDeducted,
                                        2
                                      )} (${
                                        item.totalNoOfTimesDeducted
                                      } Deduction${
                                        item.totalNoOfTimesDeducted > 1
                                          ? "s"
                                          : ""
                                      })`}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Last Deducted"
                                      value={textFormatService.convertKoboToNaira(
                                        item.lastAmountDeducted,
                                        2
                                      )}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Last Deduction Date"
                                      value={
                                        item.lastAmountDeducted === 0
                                          ? "N/A"
                                          : textFormatService.formatTimeString(
                                              item.lastDeductionDate
                                            )
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion>
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`1`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-layer-group"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Guarantor Repayment Transactions
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`1`}>
                        <Card.Body>
                          <DetailItemsTable
                            title=""
                            item={this.props.item}
                            // handleOnPageChange={() => {}}
                            handleOnPageChange={
                              this
                                .handleOnSecondaryMandateTransactionsPageChange
                            }
                            content={this.props.secondaryMandateTransactions}
                            contentCount={
                              this.props.totalSecondaryMandateTransactions
                            }
                            page={
                              this.props.secondaryMandateTransactionsCurrentPage
                            }
                            fetching={
                              this.props.fetchingSecondaryMandateTransactions
                            }
                            amountColumn={["amountDebited"]}
                            timeColumn={["sweepTime"]}
                            emptyTitle="Sweep Transactions"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-list-alt"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Linked Accounts
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            {/* <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={1} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="First Date Linked"
                                      value={item.firstDateLinked}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={1} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Last Date Linked"
                                      value={item.lastDateLinked}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                            <br /> */}
                            <div
                              className="card-content table-responsive text-nowrap"
                              style={{ height: "150px", overflowY: "scroll" }}
                            >
                              <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Institution Name</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Bank Code</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Account Number</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>First Date Linked</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Last Date Linked</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Linking Status</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.isEmpty(item.bvnAccounts) ? (
                                    <tr>
                                      <td
                                        className="font-weight-normal font-italic text-truncate"
                                        colSpan={7}
                                        style={{
                                          textAlign: "center",
                                          color: "#FE825E",
                                        }}
                                      >
                                        ...No Linked Institutions for this
                                        Mandate.
                                      </td>
                                    </tr>
                                  ) : (
                                    item.bvnAccounts.map((_item) => (
                                      <React.Fragment
                                        key={
                                          item.bvnAccounts.indexOf(_item) + 1
                                        }
                                      >
                                        <tr>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {item.bvnAccounts.indexOf(_item) +
                                              1}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.institutionName}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.institutionCode}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.accountNumber}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {textFormatService.formatTimeString(
                                              _item.firstDateLinked
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_.isEmpty(_item.lastDateLinked)
                                              ? ""
                                              : textFormatService.formatTimeString(
                                                  _item.dateLinked
                                                )}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.linkingStatus ===
                                            "Validated" ? (
                                              <span className="badge badge-light">
                                                Validated
                                              </span>
                                            ) : (
                                              <span className="badge badge-secondary">
                                                Linked
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="card">
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            General Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Name"
                                      value={item.customerName}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="BVN"
                                      value={item.bvn}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="BVN Phone Number"
                                      value={item.bvnPhoneNumber}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    {!_.isEmpty(item.remitaMandateId) && (
                                      <DetailItem
                                        label="Remita Mandate Id"
                                        value={item.remitaMandateId}
                                        labelSize={6}
                                        valueSize={6}
                                      />
                                    )}
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Account Phone Number"
                                      value={item.phoneNumber}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Identifier Type"
                                      value={item.identifierType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Repayment Type"
                                      value={item.repaymentType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Loan Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Loan Reference"
                                      value={item.loanReference}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Loan Amount"
                                      value={textFormatService.convertKoboToNaira(
                                        item.loanAmount,
                                        2
                                      )}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Loan Expiration Date"
                                      value={item.loanExpirationDate}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={2} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Repayment Account"
                                      value={item.linkedAccountNumber}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Loan Tenure"
                                      value={item.loanTenure}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Mandate Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={4} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Mandate Type"
                                      value={item.mandateType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Date Created"
                                      value={item.dateCreated}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Request Status"
                                      // value={textFormatService.splitAtUpper(
                                      //   item.requestStatus
                                      // )}
                                      value={item.requestStatus}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    {item.repaymentType === "Collection" && (
                                      <DetailItem
                                        label="Switch To Recovery"
                                        value={
                                          <Fragment>
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={() =>
                                                  this.handleOnRecoveryModeSwitch(
                                                    item
                                                  )
                                                }
                                                id="status"
                                                name="status"
                                                defaultChecked={
                                                  item.repaymentType === "Recovery"
                                                }
                                                value={item.status}
                                              />
                                              <span className="slider round"></span>
                                            </label>
                                            {/* {textFormatService.splitAtUpper(
                                          item.status
                                        )} */}
                                          </Fragment>
                                        }
                                        labelSize={6}
                                        valueSize={6}
                                      />
                                    )}
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={4} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Expected Repayment"
                                      value={textFormatService.convertKoboToNaira(
                                        item.totalRepaymentExpected,
                                        2
                                      )}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Consent Receipt Date"
                                      value={item.consentReceiptDate}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Consent Receipt Channel"
                                      value={item.consentReceiptChannel}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Channel"
                                      value={item.channel}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Sweep Information
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Sweep Type"
                                      value={item.sweepType}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label={`Total Amount Deducted`}
                                      value={`${textFormatService.convertKoboToNaira(
                                        item.totalAmountDeducted,
                                        2
                                      )} (${
                                        item.totalNoOfTimesDeducted
                                      } Deduction${
                                        item.totalNoOfTimesDeducted > 1
                                          ? "s"
                                          : ""
                                      })`}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Last Deducted"
                                      value={textFormatService.convertKoboToNaira(
                                        item.lastAmountDeducted,
                                        2
                                      )}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Last Deduction Date"
                                      value={
                                        item.lastAmountDeducted === 0
                                          ? "N/A"
                                          : textFormatService.formatTimeString(
                                              item.lastDeductionDate
                                            )
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-list-alt"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Linked Accounts
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            {/* <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={1} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="First Date Linked"
                                      value={item.firstDateLinked}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={1} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Last Date Linked"
                                      value={item.lastDateLinked}
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                            <br /> */}
                            <div
                              className="card-content table-responsive text-nowrap"
                              style={{ height: "150px", overflowY: "scroll" }}
                            >
                              <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Institution Name</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Bank Code</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Account Number</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>First Date Linked</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Last Date Linked</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Linking Status</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.isEmpty(item.bvnAccounts) ? (
                                    <tr>
                                      <td
                                        className="font-weight-normal font-italic text-truncate"
                                        colSpan={7}
                                        style={{
                                          textAlign: "center",
                                          color: "#FE825E",
                                        }}
                                      >
                                        ...No Linked Institutions for this
                                        Mandate.
                                      </td>
                                    </tr>
                                  ) : (
                                    item.bvnAccounts.map((_item) => (
                                      <React.Fragment
                                        key={
                                          item.bvnAccounts.indexOf(_item) + 1
                                        }
                                      >
                                        <tr>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {item.bvnAccounts.indexOf(_item) +
                                              1}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.institutionName}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.institutionCode}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.accountNumber}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {textFormatService.formatTimeString(
                                              _item.firstDateLinked
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_.isEmpty(_item.lastDateLinked)
                                              ? ""
                                              : textFormatService.formatTimeString(
                                                  _item.dateLinked
                                                )}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {_item.linkingStatus ===
                                            "Validated" ? (
                                              <span className="badge badge-light">
                                                Validated
                                              </span>
                                            ) : (
                                              <span className="badge badge-secondary">
                                                Linked
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion>
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`1`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-layer-group"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Repayment Transactions
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`1`}>
                        <Card.Body>
                          <DetailItemsTable
                            title=""
                            item={item}
                            handleOnPageChange={
                              this.handleOnMandateTransactionsPageChange
                            }
                            content={mandateTransactions}
                            contentCount={totalMandateTransactions}
                            page={mandateTransactionsCurrentPage}
                            fetching={fetchingMandateTransactions}
                            amountColumn={["amountDebited"]}
                            timeColumn={["sweepTime"]}
                            contextualColumn={tableContext}
                            emptyTitle="Sweep Transactions"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
              </div>
            </React.Fragment>
          )}

          {item.hasGuarantor === true ? (
            <div className="card mt-4">
              <div>
                <DetailItemsTable
                  title="Guarantor(s) Information"
                  item={item}
                  handleOnPageChange={() => {}}
                  hasDetailModal={true}
                  onViewDetails={(secondaryMandate) => {
                    this.fetchFullGuarantorMandate(secondaryMandate);
                    this.handleOnToggleMandateModal();
                  }}
                  content={
                    _.isEmpty(item)
                      ? []
                      : mapEntityList({
                          entityList: item.secondaryMandates,
                          properties: [
                            {
                              oldName: "name",
                              newName: "guarantorName",
                            },
                            {
                              oldName: "bvn",
                              newName: "bvn",
                            },
                            {
                              oldName: "phoneNumber",
                              newName: "phoneNumber",
                            },
                            {
                              oldName: "guaranteedAmount",
                              newName: "guaranteedAmount",
                            },
                            {
                              oldName: "guaranteeingPercentage",
                              newName: "guaranteedPercentage",
                            },
                          ],
                        })
                  }
                  contentCount={
                    _.isEmpty(item) ? 0 : item.secondaryMandates.length
                  }
                  page={1}
                  fetching={_.isEmpty(item) ? true : false}
                  timeColumn={["dateAdded"]}
                  amountColumn={["guaranteedAmount"]}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          {item.secondaryMandates?.length > 0 && (
            <React.Fragment>
              {item.status === "Cancelled" ? (
                <dl className="row" style={{ marginBottom: "0px" }}>
                  <DetailItem
                    label="Comment"
                    value={item.comment}
                    labelSize={2}
                    valueSize={10}
                  />
                </dl>
              ) : (
                <React.Fragment>
                  <label htmlFor="comment">Comment: </label>
                  <textarea
                    className="form-control r-0"
                    id="comment"
                    value={comment}
                    onChange={(e) => this.handleOnCommentChange(e)}
                    rows={3}
                  ></textarea>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {/* <div className="form-group">
            <Card>
              <Card.Body>
                
              </Card.Body>
            </Card>
          </div> */}
          <MandateDetail
            showDetailsModal={this.state.showGuarantorDetailsModal}
            toggleDetailsModal={() => {
              this.handleOnToggleMandateModal();
            }}
            showGuarantorDetails={true}
            secondaryMandateTransactions={
              this.state.secondaryMandateTransactions
            }
            totalSecondaryMandateTransactions={
              this.state.totalSecondaryMandateTransactions
            }
            secondaryMandateTransactionsCurrentPage={
              this.state.secondaryMandateTransactionsCurrentPage
            }
            fetchingSecondaryMandateTransactions={
              this.state.fetchingSecondaryMandateTransactions
            }
            item={this.state.guarantorItem}
          />
        </Modal.Body>
        <Modal.Footer>
          {this.props.item.status !== "Cancelled" &&
            // item.secondaryMandates?.length > 0 &&
            getCurrentUsersRoles().includes("cancelmandate") && (
              <Button
                size="sm"
                variant="outline-warning"
                onClick={() => {
                  this.cancelMandate();
                  toggleDetailsModal();
                }}
              >
                <i className="fas fa-ban mr-3"></i>
                {/* <i className="fas fa-times mr-3" /> */}
                Cancel
              </Button>
            )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MandateDetail;
